import React, { useEffect, useState } from "react";
import "./SignUpFlow.scss";
import SignupNavbar from "./Components/SignupNavbar/SignupNavbar";
import SignupFooter from "./Components/SignupFooter/SignupFooter";
import BusinessDetails from "./Pages/BusinessDetails/BusinessDetails";
import YourDetails from "./Pages/YourDetails/YourDetails";
import HaveDomain from "./Pages/HaveDomain/HaveDomain";
import EnterDomain from "./Pages/EnterDomain/EnterDomain";
import SelectDomain from "./Pages/SelectDomain/SelectDomain";
import CreateAccount from "./Pages/CreateAccount/CreateAccount";
import UserLink from "./Pages/UserLink/WelcomePage";
import { useSignUpFlowContext } from "./GlobalStates";

const SignUpFlow = (props) => {
  const [agree, setAgree] = useState(false);
  const { selectedPage, setPageWidth, footerHeight, pageWidth,mode } =
    useSignUpFlowContext();

  useEffect(() => {
    caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
    });
    setPageWidth(document.getElementById("root").clientWidth);
  });

  return (
    <div
      className={`SignupFlow-${mode}`}
      style={{
        height: pageWidth < 1200 ? `calc(100vh - ${footerHeight}px)` : "100vh",
      }}
    >
      <header>
        <SignupNavbar />
      </header>
      <div
        className="business-details"
        style={{
          justifyContent:
            pageWidth < 501 &&
            (selectedPage === 9 || selectedPage === 6) &&
            "flex-start",
        }}
      >
        {selectedPage === 1 && (
          <BusinessDetails setAgree={setAgree} agree={agree} />
        )}
        {selectedPage === 2 && <YourDetails />}
        {selectedPage === 3 && <HaveDomain />}
        {selectedPage === 4 && <EnterDomain />}
        {selectedPage === 5 && <SelectDomain />}
        {selectedPage === 6 && <CreateAccount CreateAccount />}
        {selectedPage === 7 && <UserLink />}
      </div>
      <footer>
        <SignupFooter />
      </footer>
    </div>
  );
};

export default SignUpFlow;
