import React, { useEffect } from "react";
import InputContainer from "../../../Signup/InputContainer/InputContainer";
import "./../../SignUpFlow.scss";
import { validateOTP, sendOTP } from "../../API_reqs";
import { useSignUpFlowContext } from "../../GlobalStates";
import { countryCodes } from "../../GlobalStates";

const YourDetails = () => {
  const {
    firstName,
    setFirstName,
    secondName,
    setSecondName,
    email,
    setEmail,
    otpEmail,
    number,
    setNumber,
    otpSent,
    otp,
    setOtp,
    numberError,
    setNumberError,
    emailError,
    setemailError,
    firstNameError,
    setfirstNameError,
    secondNameError,
    setsecondNameError,
    firstNameErrorText,
    secondNameErrorText,
    emailErrorText,
    otpError,
    setOtpError,
    otpErrorText,
    setOtpErrorText,
    numberErrorText,
    setNumberErrorText,
    otpDone,
    setOtpDone,
    setOtpEmail,
    emailSubText,
    minutes,
    setMinutes,
    seconds,
    setSeconds,
    timeOut,
    setTimeOut,
    setemailErrorText,
    setOtpSent,
    setLoader,
    footerHeight,
    pageWidth,
    mode,
    setCountryCode
  } = useSignUpFlowContext();


  // Function to decrement the countdown
  const decrementCountdown = () => {
    if (minutes === 0 && seconds === 0) {
      // Countdown is complete
      // clearInterval(intervalId);
      setTimeOut(true);
    } else {
      if (seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        setSeconds(seconds - 1);
      }
    }
  };

  const handleCountryCode = (e) => {
    setCountryCode(e.target.value);
  }

  // Effect to start the countdown when the component mounts
  useEffect(() => {
    setLoader(30);
    const intervalId = setInterval(decrementCountdown, 1000); // Decrease countdown every 1000ms (1 second)
    // Clean up the timer when the component unmounts
    return () => clearInterval(intervalId);
  }, [seconds, minutes]);

  return (
    <div
      className="business-details-wrapper"
      style={{
        paddingBottom: pageWidth > 900 && `${footerHeight}px`,
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <center>
        <h2 className="your-details-header">Your Details</h2>
        <div className="your-details-name-container">
          <div className="your-details-name-inputs">
            <InputContainer
              signUp
              color={mode === "darkmode" ? "white" : "#000"}
              height="4.18rem"
              fontSize="1rem"
              marginTop="0.6rem"
              placeholder="First Name"
              value={firstName}
              type="firstName"
              onChange={(e) => {
                setFirstName(e.target.value);
                setfirstNameError(false);
              }}
              error={firstNameError}
              errorText={firstNameErrorText}
            />
          </div>
          <div className="your-details-name-inputs">
            <InputContainer
              signUp
              color={mode === "darkmode" ? "white" : "#000"}
              height="4.18rem"
              fontSize="1rem"
              marginTop="0.6rem"
              placeholder="Last Name"
              value={secondName}
              type="lastname"
              onChange={(e) => {
                setSecondName(e.target.value);
                setsecondNameError(false);
              }}
              error={secondNameError}
              errorText={secondNameErrorText}
            />
          </div>
        </div>
        <InputContainer
          signUp
          signUpFlow
          display="flex"
          color={mode === "darkmode" ? "white" : "#000"}
          height="4.18rem"
          fontSize="1rem"
          marginTop="1.688rem"
          placeholder="Your Email"
          value={email}
          type="side-button"
          onChange={(e) => {
            setEmail(e.target.value);
            setemailError(false);
          }}
          error={emailError}
          errorText={emailErrorText}
        />
        <div className="sub-text">
          {otpSent && !otpDone ? (
            <>
              We sent the OTP to <u>{otpEmail}</u>.{" "}
              {timeOut ? (
                <button
                  style={{
                    color: "#147CFF",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={() => {
                    sendOTP({
                      email: email,
                    })
                      .then(async (response) => {
                        if (response.status === 201) {
                          setMinutes(Math.floor(90 / 60));
                          setSeconds(90 % 60);
                          setOtpEmail(email);
                          setOtpSent(true);
                          setTimeOut(false);
                        }
                      })
                      .catch((error) => {
                        setemailError(true);
                        setemailErrorText(
                          "OTP Sending error. Please try again."
                        );
                      });
                  }}
                >
                  Resend OTP
                </button>
              ) : (
                <>
                  OTP sent to <i>{email}</i>.
                  <br />
                  Time left: {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </>
              )}
            </>
          ) : otpDone ? (
            "Thank you for verifyng your email address."
          ) : (
            emailSubText
          )}
        </div>

        {otpSent ? (
          <InputContainer
            signUp
            otp
            color={mode === "darkmode" ? "white" : "#000"}
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="Enter OTP"
            value={otp}
            type="text"
            display="flex"
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setOtp(e.target.value);
                setOtpError(false);
                setOtpDone(false);
                if (e.target.value.length >= 6) {
                  validateOTP({
                    email: email,
                    otp: e.target.value,
                  })
                    .then(async (response) => {
                      if (response.status === 200) {
                        setOtpDone(true);
                        setOtpEmail(email);
                        setOtpError(false);
                        setemailError(false);
                        setMinutes(0);
                        setSeconds(0);
                      } else if (response.status === 401) {
                        setOtpErrorText("Invalid OTP. Please try again.");
                        setOtpError(true);
                      }
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        setOtpErrorText("Invalid OTP. Please try again.");
                      } else if (error.response.status === 403) {
                        setOtpErrorText(
                          "OTP Expired. Please request another OTP"
                        );
                      }
                      setOtpError(true);
                    });
                }
              }
            }}
            error={otpError}
            errorText={otpErrorText}
          />
        ) : (
          ""
        )}
        {otpDone ? (
          <div className="DatePicker">
            <select className="selector" placeholder="countryCode" id="countryCode" name="countryCode" onChange={handleCountryCode} style={{'marginTop':'30px'}}>
              <option value="">--</option>
              {countryCodes.map((countryCode, i) => {
                return (
                  <option
                    className="select-option-style"
                    key={i}
                    value={countryCode.dial_code}
                  >
                    {countryCode.dial_code} - {countryCode.name}
                  </option>
                );
              })}
            </select>
            <InputContainer
              signUp
              color={mode === "darkmode" ? "white" : "#000"}
              height="4.18rem"
              fontSize="1rem"
              marginTop="1.688rem"
              placeholder="Your Contact Number"
              value={number}
              type="text"
              onChange={(e) => {
                const regex = /^[0-9+]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  // setNumberErrorText("Please provide a valid phone number");
                  // setNumber(e.target.value);
                  // setNumberError(false);
                  const element = document.getElementById('countryCode');
                  let code = element.value;
                  if(code !== ""){
                    setNumber(e.target.value);
                    setNumberError(false);
                    setNumberErrorText("Invalid Phone Number")
                  }
                  else{
                    setNumberError(true);
                    setNumberErrorText("Country Code is required");
                  }
                }
              }}
              error={numberError}
              errorText={numberErrorText}
            />
          </div>
        ) : (
          <InputContainer
            disabled
            color={mode === "darkmode" ? "white" : "#000"}
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.688rem"
            placeholder="Your Contact Number"
            value={number}
            type="text"
            display="none"
            onChange={(e) => {
              setNumber(e.target.value);
              setNumberError(false);
            }}
            error={numberError}
            errorText={numberErrorText}
          />
        )}
      </center>
    </div>
  );
};

export default YourDetails;

//fix countdown timer
//fix otp resend
//fix otp resend on timeout
//fix phome number input
//add green tick on otp - Done
