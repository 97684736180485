const developemnt = {
  apiEndpoints: {
    protocol: "http://",
    messageServer: "binarytechnologies.xyz:5000/api",
    authServer: "binarytechnologies.xyz:4000",
  },
  emailProvider: "@binarytechnologies.xyz",
  emailText: "binarytechnologies.xyz",
  websocketURL:
    "wss://amuv8wkzwf.execute-api.ap-south-1.amazonaws.com/bix-dev",

  domain: {
    app: "https://binarytechnologies.xyz",
    auth: "https://auth.binarytechnologies.xyz",
    cloud: "https://cloud.binarytechnologies.xyz",
  },
  cookieDomain: ".binarytechnologies.xyz",
  gleap: "xIQqca7mkYR73iFcUSnkorfrI8kqf7sD",
};
const staging = {
  apiEndpoints: {
    protocol: "https://",
    messageServer: "api.bixchat.xyz/message/api",
    authServer: "api.bixchat.xyz/auth",
  },
  emailProvider: "@bixchat.xyz",
  emailText: "bixchat.xyz",
  websocketURL:
    "wss://amuv8wkzwf.execute-api.ap-south-1.amazonaws.com/bix-staging",

  domain: {
    app: "https://app.bixchat.xyz",
    auth: "https://auth.bixchat.xyz",
    cloud: "https://cloud.bixchat.xyz",
  },
  cookieDomain: ".bixchat.xyz",
  gleap: "xIQqca7mkYR73iFcUSnkorfrI8kqf7sD",
};

const production = {
  apiEndpoints: {
    protocol: "https://",
    messageServer: "fod8v8r3f8.execute-api.ap-southeast-1.amazonaws.com/api",
    authServer: "pkpp3fq3q6.execute-api.ap-southeast-1.amazonaws.com/Prod",
  },
  emailProvider: "@zapmail.lk",
  emailText: "zapmail.lk",
  websocketURL:
    "wss://amuv8wkzwf.execute-api.ap-south-1.amazonaws.com/huawei-staging",
    domain: {
      app: "https://zapmail.lk",
      auth: "https://auth.zapmail.lk",
      cloud: "https://cloud.zapmail.lk",
    },
    cookieDomain: ".zapmail.lk",
  gleap: "xIQqca7mkYR73iFcUSnkorfrI8kqf7sD",
};

let basicExport = developemnt; // developemnt || staging || production

if (process.env.REACT_APP_ENV === "dev") {
  basicExport = developemnt;
} else if (process.env.REACT_APP_ENV === "stage") {
  basicExport = staging;
} else if (process.env.REACT_APP_ENV === "prod") {
  basicExport = production;
}

export default basicExport;
