import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ForgetPassword from "./pages/Auth/ForgetPassword/ForgetPassword";
import Login from "./pages/Auth/Login/Login";
import AppProvider from "./ContextApi/AppProvider";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import PasswordChange from "./pages/Auth/PasswordChange/PasswordChange";
import ForgotPasswordLink from "./pages/Auth/ForgotPasswordLink/ForgotPasswordLink";
import Home from "./pages/Home";
import Signup from "./pages/Auth/Signup/Signup";
import BusinessSignup from "./pages/Auth/BusinessSignup/BusinessSignup";
import WelcomePage from "./pages/Auth/SignUpFlow/Pages/UserLink/WelcomePage";
import SignUpFlow from "./pages/Auth/SignUpFlow/SignUpFlow";
import OtpForgotPassword from "./pages/Auth/ForgetPassword/OtpForgotPassword";
import Upsell from "./pages/Auth/Upsell";
import PaymentConfirm from "./pages/Auth/PaymentConfirm";
import PaymentBasic from "./pages/Auth/PaymentBasic";
import RecoveryEmail from "./pages/Auth/RecoveryEmail/RecoveryEmail";
import PaymentSuccess from "./pages/Auth/PaymentSuccess";
import PaymentCancel from "./pages/Auth/PaymentCancel";
import PaymentCheck from "./pages/Auth/PaymentCheck";
import AddAccount from "./pages/Auth/AddAccount";

export default function Routes() {
  return (
    <div>
      <Router>
        <AppProvider>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route
              exact
              path="/forget-password"
              component={OtpForgotPassword}
            />
            <Route exact path="/forget-password/2" component={ForgetPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/password-change" component={PasswordChange} />
            <Route
              exact
              path="/reset-password-business"
              component={ForgotPasswordLink}
            />
            <Route exact path="/home" component={Home} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/business-signup" component={BusinessSignup} />
            <Route exact path="/pay/:item" component={PaymentBasic} />
            <Route exact path="/signup-flow-details" component={SignUpFlow} />
            <Route exact path="/signup-flow/:flow" component={Upsell} />
            <Route exact path="/signup-flow" component={Upsell} />
            <Route exact path="/confirm/:redirect" component={PaymentConfirm} />
            <Route exact path="/add-accounts" component={AddAccount} />
            <Route exact path="/payment/success" component={PaymentSuccess} />
            <Route exact path="/payment/check" component={PaymentCheck} />
            <Route exact path="/payment/cancel" component={PaymentCancel} />
            <Route
              exact
              path="/signup-flow/user-link/:domain_id"
              component={WelcomePage}
            />
            <Route exact path="/recovery-email" component={RecoveryEmail} />
          </Switch>
        </AppProvider>
      </Router>
    </div>
  );
}
